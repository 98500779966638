import React from "../../_snowpack/pkg/react.js";
import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {add} from "../../_snowpack/pkg/date-fns.js";
import {useEffect, useState} from "../../_snowpack/pkg/react.js";
export const Charades = ({
  teamA,
  teamB,
  startTime
}) => {
  let wordA = WORDS_A[teamA];
  let wordB = WORDS_B[teamB];
  let timerStart = startTime ? new Date(startTime) : null;
  let [now, setNow] = useState(() => new Date());
  useEffect(() => {
    let interval = setInterval(() => {
      setNow(new Date());
    }, 50);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let endTime = timerStart && add(timerStart, {minutes: 2});
  let duration = endTime && endTime.getTime() - now.getTime();
  let seconds = duration && Math.floor(duration / 1e3);
  let milliseconds = duration && Math.floor(duration / 10) % 100;
  let minutes = seconds && Math.floor(seconds / 60);
  seconds = seconds && seconds % 60;
  console.log({minutes, seconds});
  console.log({timerStart, now, endTime});
  let hasEnded = endTime && endTime < now;
  return /* @__PURE__ */ jsx("div", {
    css: css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows auto auto;
                grid-column-gap: 6em;
                justify-content: center;
                align-content: center;
                margin-top: 10vh;


                .word {
                    text-align: center;
                    font-size: 6em;
                    font-weight: 900;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 260px;
                }
            `
  }, /* @__PURE__ */ jsx("div", {
    css: css`
                    font-size: 5em;
                    grid-column: 1 / span 2;
                    text-align: center;
                    font-family: dseg7;
                    margin-bottom: 24px;
                `
  }, hasEnded ? "00:00:00" : duration ? `${formatInt(minutes || 0)}:${formatInt(seconds || 0)}:${formatInt(milliseconds || 0)}` : "02:00:00"), !hasEnded && /* @__PURE__ */ jsx(React.Fragment, null, /* @__PURE__ */ jsx("div", {
    className: "word"
  }, wordA), /* @__PURE__ */ jsx("div", {
    className: "word"
  }, wordB)));
};
const WORDS_A = [
  "Red Team",
  "Gummistøvler",
  "Bølge",
  "Krangle",
  "Politibåt",
  "Basketball",
  "Due",
  "Viskelær",
  "Motorsag",
  "Elefant",
  "Gitar",
  "Salmebok",
  "Minibuss",
  "Fredrik",
  "Sky",
  "Telt",
  "Ambulanse fly",
  "Fallskjerm-hopping",
  "Svane",
  "Bordtennis",
  "Gressklipper",
  "Kritt",
  "Trompet",
  "Ku",
  "Tog",
  "Bibel"
];
const WORDS_B = [
  "Blue Team",
  "Sky",
  "Telt",
  "Ambulanse fly",
  "Fallskjerm-hopping",
  "Svane",
  "Bordtennis",
  "Gressklipper",
  "Kritt",
  "Trompet",
  "Ku",
  "Tog",
  "Bibel",
  "Fredrik",
  "Gummistøvler",
  "Bølge",
  "Krangle",
  "Politibåt",
  "Basketball",
  "Due",
  "Viskelær",
  "Motorsag",
  "Elefant",
  "Gitar",
  "Salmebok",
  "Minibuss"
];
function formatInt(number) {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number.toString();
  }
}
