"use client";
import React, {useEffect, useRef} from "../../_snowpack/pkg/react.js";
import io from "../../_snowpack/pkg/socket.io-client.js";
const socket = io("wss://ws.tanumpoint.no");
const availableSrc = ["https://tanumpoint.no/presenter", "https://timer.tanumpoint.no/timer?main=title&secondary-src=custom-hvem"];
export const Timer = () => {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = React.useState("https://tanumpoint.no/presenter");
  useEffect(() => {
    socket.on("iframeUpdate", (message, data) => {
      console.log("Received message:", message);
      console.log("Received data:", data);
      setIframeSrc(message.iframeSrc);
    });
    socket.emit("iframeUpdate", {secret: ""});
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    style: {width: "100vw", height: "100vh"}
  }, availableSrc.map((src) => /* @__PURE__ */ React.createElement("iframe", {
    key: src,
    ref: iframeRef,
    src,
    width: "100%",
    height: "100%",
    title: "Iframe",
    allow: "fullscreen; autoplay;",
    style: {display: src === iframeSrc ? "block" : "none"}
  })));
};
export default Timer;
