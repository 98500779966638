import styled from "../../_snowpack/pkg/@emotion/styled.js";
import React from "../../_snowpack/pkg/react.js";
import {TransitionGroup, CSSTransition} from "../../_snowpack/pkg/react-transition-group.js";
import {FILE_SERVE_URL} from "../api.js";
import {sc, useNats, useNatsReq} from "../nats.js";
import {Charades} from "./Charades.js";
import {Logo} from "./Logo.js";
import {MoveIt} from "./Moveit.js";
import {ViewHtml} from "./ViewHtml.js";
import {Counter} from "./Counter.js";
export const Viewer = ({scale, type}) => {
  const viewReq = useNatsReq("presenter.view");
  const view = viewReq.data || {t: "Nothing"};
  if (viewReq.error) {
    return /* @__PURE__ */ React.createElement(Wrapper, {
      scale
    }, viewReq.error.toString());
  }
  return /* @__PURE__ */ React.createElement(Wrapper, {
    scale
  }, /* @__PURE__ */ React.createElement(TransitionGroup, null, /* @__PURE__ */ React.createElement(CSSTransition, {
    timeout: 200,
    classNames: "fade",
    key: getTransitionKey(view)
  }, /* @__PURE__ */ React.createElement(InnerWrapper, null, /* @__PURE__ */ React.createElement(Inner, {
    view,
    type
  })))));
};
function getTransitionKey(view) {
  switch (view.t) {
    case "Nothing":
      return "nothing";
    case "Logo":
      return "logo";
    case "Verse":
      return JSON.stringify(view);
    case "KampKveld":
      return "kampkveld";
    case "Moveit":
      return "moveit";
  }
}
const Inner = ({view, type}) => {
  switch (view.t) {
    case "Nothing":
      return /* @__PURE__ */ React.createElement("div", null);
    case "Logo":
      return /* @__PURE__ */ React.createElement(Logo, null);
    case "Verse":
      return /* @__PURE__ */ React.createElement(SongVerse, {
        verse: view.c,
        type
      });
    case "KampKveld":
      return /* @__PURE__ */ React.createElement(KampKveld, null);
    case "Moveit":
      return /* @__PURE__ */ React.createElement(MoveIt, {
        type: view.c
      });
    case "Charades":
      return /* @__PURE__ */ React.createElement(Charades, {
        teamA: view.c[0],
        teamB: view.c[1],
        startTime: view.c[2]
      });
    case "Counter":
      return /* @__PURE__ */ React.createElement(Counter, {
        startTime: view.c
      });
  }
};
const SongVerse = ({verse, type}) => {
  const nc = useNats();
  switch (verse.t) {
    case "Lines":
      return /* @__PURE__ */ React.createElement(VerseStyle, null, verse.c.map((line, i) => /* @__PURE__ */ React.createElement("div", {
        key: i
      }, line)));
    case "Image":
      return /* @__PURE__ */ React.createElement(VerseStyle, null, /* @__PURE__ */ React.createElement("img", {
        src: FILE_SERVE_URL + verse.c
      }));
    case "Video":
      return /* @__PURE__ */ React.createElement(VerseStyle, null, type === "present" ? /* @__PURE__ */ React.createElement("video", {
        autoPlay: true,
        src: FILE_SERVE_URL + verse.c.url,
        onEnded: () => nc.publish("song_player.video_playback.finished", sc.encode(verse.c.url))
      }) : /* @__PURE__ */ React.createElement("div", null, "Video: ", verse.c.url));
    case "Blank":
      return /* @__PURE__ */ React.createElement("div", null);
    case "Logo":
      return /* @__PURE__ */ React.createElement(Logo, null);
    case "Html":
      return /* @__PURE__ */ React.createElement(ViewHtml, {
        html: verse.c
      });
    default:
      return assertNever(verse);
  }
};
function assertNever(x) {
  return /* @__PURE__ */ React.createElement("div", null, "Unexpected: ", JSON.stringify(x));
}
const VerseStyle = styled.div`
    text-align: center;
    font-size: 5.2em;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 600;

    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
const KampKveld = () => {
  let res = useNatsReq("kampkveld.scores");
  if (res.error) {
    return /* @__PURE__ */ React.createElement("div", null, res.error.toString());
  }
  if (res.isLoading || !res.data) {
    return /* @__PURE__ */ React.createElement(KampkveldStyle, null);
  }
  let {red, blue} = res.data;
  return /* @__PURE__ */ React.createElement(KampkveldStyle, null, /* @__PURE__ */ React.createElement("div", {
    className: "teamName"
  }, "RED TEAM"), /* @__PURE__ */ React.createElement("div", {
    className: "teamName"
  }, "BLUE TEAM"), /* @__PURE__ */ React.createElement("div", {
    className: "digits red"
  }, red), /* @__PURE__ */ React.createElement("div", {
    className: "digits blue"
  }, blue));
};
const KampkveldStyle = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 6em;
    justify-content: center;
    align-content: center;
    height: 100%;

    .teamName {
        text-align: center;
        font-size: 6em;
        font-weight: 900;
    }

    .digits {
        font-family: dseg7;
        font-size: 20em;
        text-align: center;
        &.red {
            color: #d50000;
        }

        &.blue {
            color: #0067ff;
        }
    }
`;
const InnerWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;
const Wrapper = styled.div`
    font-size: ${(props) => props.scale};
    height: 100%;
    position: relative;
    overflow: hidden;
    color: #fff;

    .fade-enter {
        opacity: 0.01;
    }

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 100ms ease-out;
        transition-delay: 30ms;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit.fade-exit-active {
        opacity: 0;
        transition: opacity 100ms ease-in;
    }
`;
