import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import Slider, {SliderHandleAlignment, SliderOrientation} from "../../_snowpack/pkg/@reach/slider.js";
import {colorToCssColor} from "../lights/colors.js";
import {sc, useNats, useNatsReq} from "../nats.js";
import {buttonReset, colors} from "../styles.js";
export const Lights = () => {
  let groups = useNatsReq("lights.groups", {
    refetchOnWindowFocus: false
  });
  let cues = useNatsReq("lights.cues", {
    refetchOnWindowFocus: false
  });
  if (groups.error) {
    return /* @__PURE__ */ jsx("div", null, groups.error.toString());
  }
  if (cues.error) {
    return /* @__PURE__ */ jsx("div", null, cues.error.toString());
  }
  if (groups.isLoading || !groups.data || cues.isLoading || !cues.data) {
    return /* @__PURE__ */ jsx("div", null, "Loading..");
  }
  return /* @__PURE__ */ jsx("div", {
    css: css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            `
  }, groups.data.map((g) => /* @__PURE__ */ jsx(GroupControl, {
    group: g,
    cues: cues.data
  })));
};
export const GroupControl = ({
  group,
  cues
}) => {
  let nc = useNats();
  let intensity = cues[`${group.id}_intensity`]?.intensity || 0;
  return /* @__PURE__ */ jsx("div", {
    css: css`
                border: 1px solid ${colors.textMed};
                padding: 24px 64px;
                margin: 8px;
            `
  }, /* @__PURE__ */ jsx("div", {
    css: css`
                    display: flex;
                    align-items: center;
                `
  }, /* @__PURE__ */ jsx(Slider, {
    css: css`
                        margin-right: 24px;
                        [data-reach-slider-handle] {
                            background: #ccc;
                            width: 24px;
                            height: 48px;
                        }
                    `,
    min: 0,
    max: 1,
    step: 1e-3,
    value: intensity,
    onChange: (value) => {
      if (value == 0) {
        nc.publish(`lights.cues.stop.${group.id}_intensity`);
      } else {
        nc.publish(`lights.cues.set.${group.id}_intensity`, sc.encode(JSON.stringify(value)));
      }
    },
    orientation: SliderOrientation.Vertical,
    handleAlignment: SliderHandleAlignment.Contain
  }), group.colors.map((color) => {
    let cue_name = `${group.id}_${color.name}`;
    console.log(cues);
    let enabled = !!cues[cue_name];
    return /* @__PURE__ */ jsx("button", {
      onClick: () => enabled ? nc.publish(`lights.cues.stop.${cue_name}`) : nc.publish(`lights.cues.set.${cue_name}`, sc.encode(JSON.stringify(1))),
      css: css`
                                ${buttonReset};
                                padding: 8px 16px;
                                border: 1px solid white;
                                margin: 8px;
                                color: ${enabled ? "black" : "white"};
                                font-variant: all-small-caps;
                                font-size: 24px;
                                border-radius: 3px;
                                background: ${enabled ? colorToCssColor(color.color) : "transparent"};
                            `
    }, color.name);
  })), /* @__PURE__ */ jsx("div", {
    css: css`
                    font-variant: all-small-caps;
                    font-size: 32px;
                    min-width: 100px;
                    text-align: center;
                `
  }, group.name));
};
