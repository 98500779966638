import { c as createCommonjsModule } from '../common/_commonjsHelpers-668e6127.js';

var devtools = createCommonjsModule(function (module) {
{
  module.exports = {
    ReactQueryDevtools: function () {
      return null
    },
    ReactQueryDevtoolsPanel: function () {
      return null
    },
  };
}
});

var ReactQueryDevtools = devtools.ReactQueryDevtools;
export { ReactQueryDevtools };
