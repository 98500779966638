import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import useMeasure from "../../../_snowpack/pkg/react-use-measure.js";
import {button, buttonReset} from "../../styles.js";
import {SongList} from "./SongList.js";
import {SongPreview} from "./SongPreview.js";
import {KampKveldAdmin} from "./KampKveldAdmin.js";
import {Viewer} from "../../components/Viewer.js";
import {ArrowLeft, ArrowRight} from "../../components/icons.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {previewSongAtom} from "../../data/preview.js";
import {useAtom} from "../../../_snowpack/pkg/jotai.js";
import {useSong} from "../../data/song.js";
import {useNats} from "../../nats.js";
import {Empty} from "../../../_snowpack/pkg/nats.ws.js";
export const Control = () => {
  const [rightBoxState, setRightBoxState] = useState("songs");
  const [previewSongId] = useAtom(previewSongAtom);
  const previewSong = useSong(previewSongId).data;
  const nc = useNats();
  useEffect(() => {
    const keydown = (event) => {
      console.log(event.key);
      if (event.key === "Space" || event.key === "ArrowDown" || event.key === "ArrowRight") {
        event.preventDefault();
        nc.publish(`song_player.next`, Empty);
      }
      if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        event.preventDefault();
        nc.publish(`song_player.prev`, Empty);
      }
      if (event.key === ".") {
        event.preventDefault();
        nc.publish(`presenter.view.logo`, Empty);
      }
      if (event.key === ",") {
        event.preventDefault();
        nc.publish(`presenter.view.blank`, Empty);
      }
      if (event.key === "s") {
        event.preventDefault();
        nc.publish(`song_player.update`, Empty);
      }
    };
    const keyup = (event) => {
      if (event.key === "Space") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", keydown);
    window.addEventListener("keyup", keyup);
    return () => {
      window.removeEventListener("keydown", keydown);
      window.removeEventListener("keyup", keyup);
    };
  }, []);
  let [viewerRef, viewerSize] = useMeasure();
  return /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement(H2, {
    area: "leftH"
  }, previewSong ? previewSong.title : ""), /* @__PURE__ */ React.createElement(H2, {
    area: "middleH"
  }, "Vises nå"), /* @__PURE__ */ React.createElement(RightHeading, {
    area: "rightH"
  }, /* @__PURE__ */ React.createElement(RightHeader, {
    onClick: () => setRightBoxState("songs"),
    active: rightBoxState === "songs"
  }, "Sanger"), /* @__PURE__ */ React.createElement(RightHeader, {
    onClick: () => setRightBoxState("kampkveld"),
    active: rightBoxState === "kampkveld"
  }, "Kampkveld")), /* @__PURE__ */ React.createElement(Left, null, previewSong && /* @__PURE__ */ React.createElement(SongPreview, {
    song: previewSong
  })), /* @__PURE__ */ React.createElement(Middle, null, /* @__PURE__ */ React.createElement(ViewerWrapperWrapper, null, /* @__PURE__ */ React.createElement(ViewerWrapper, {
    ref: viewerRef
  }, /* @__PURE__ */ React.createElement(Viewer, {
    scale: `${viewerSize.width / 100}px`,
    type: "preview"
  }))), /* @__PURE__ */ React.createElement(ControlButtons, null)), /* @__PURE__ */ React.createElement(Right, null, rightBoxState === "songs" && /* @__PURE__ */ React.createElement(SongList, null), rightBoxState === "kampkveld" && /* @__PURE__ */ React.createElement(KampKveldAdmin, null)));
};
const ControlButtons = () => {
  const nc = useNats();
  return /* @__PURE__ */ React.createElement(ControlButtonsStyle, null, /* @__PURE__ */ React.createElement("button", {
    onClick: () => nc.publish(`song_player.prev`, Empty)
  }, /* @__PURE__ */ React.createElement(ArrowLeft, null)), /* @__PURE__ */ React.createElement("button", {
    onClick: () => nc.publish(`song_player.next`, Empty)
  }, /* @__PURE__ */ React.createElement(ArrowRight, null)), /* @__PURE__ */ React.createElement("button", {
    onClick: () => nc.publish(`presenter.view.logo`, Empty)
  }, "Logo"), /* @__PURE__ */ React.createElement("button", {
    onClick: () => nc.publish(`presenter.view.blank`, Empty)
  }, "Blank"));
};
const gridArea = (props) => {
  if (props.area) {
    return `grid-area: ${props.area}`;
  }
  return "";
};
const ControlButtonsStyle = styled.div`
    display: flex;
    margin: 10px 0 0;
    justify-content: flex-end;
    font-size: 17px;
    font-weight: 600;

    button {
        ${button};
        height: 32px;
        min-width: 64px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
            color: #111;
            height: 80%;
        }
    }
`;
const Wrapper = styled.div`
    justify-content: center;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: 324px minmax(0, 700px) 290px;
    grid-template-areas:
        'leftH middleH rightH'
        'left  middle  right';
    margin: 20px 30px;
    grid-row-gap: 10px;
    grid-column-gap: 30px;
    align-items: flex-start;
    @media (min-width: 1001px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    @media (max-width: 1000px) {
        grid-template-rows: auto auto auto 600px auto auto;
        grid-template-columns: 100%;
        grid-template-areas:
            'leftH'
            'left'
            'middleH'
            'middle'
            'rightH'
            'right';
    }
`;
const H2 = styled.h2`
    ${gridArea};
    font-size: 18px;
    font-weight: 900;
    margin: 0 10px 0 0;
    display: block;
    font-variant: all-small-caps;
`;
const Left = styled.div`
    padding-right: 16px;
    grid-area: left;
    height: 100%;
    @media (min-width: 1001px) {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;
const Middle = styled.div`
    grid-area: middle;
`;
const ViewerWrapperWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;
const ViewerWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* This needs to be height and not minHeight because we need height: 100% to work in the children
    height: 450px; */
    background: #000;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);
`;
const Right = styled.div`
    grid-area: right;
    height: 100%;
    @media (min-width: 1001px) {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`;
const RightHeading = styled.div`
    ${gridArea};
    display: flex;
`;
const RightHeader = styled.button`
    ${buttonReset};
    font-size: 18px;
    font-weight: 900;
    margin: 0 10px 0 0;
    display: block;
    cursor: pointer;
    display: block;
    font-variant: all-small-caps;
    ${(props) => !props.active && `color: #666`};
`;
