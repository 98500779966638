import styled from "../../_snowpack/pkg/@emotion/styled.js";
import React from "../../_snowpack/pkg/react.js";
import {Viewer} from "../components/Viewer.js";
export const Presenter = () => /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement(Viewer, {
  scale: "1.0vw",
  type: "present"
}));
const Wrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    cursor: none;
`;
