import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {useNats} from "../nats.js";
import {elevation, buttonReset} from "../styles.js";
export const CounterControl = () => {
  const nc = useNats();
  return /* @__PURE__ */ jsx("div", {
    css: css`
                justify-content: center;
                margin: 64px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                button {
                    ${buttonReset};
                    display: block;
                    min-width: 128px;
                    height: 64px;
                    ${elevation[4]}
                    margin-bottom: 24px;
                }
            `
  }, /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`counter.view`)
  }, "Vis nedtelling"), /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`counter.start`)
  }, "Start nedtelling"), /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`counter.reset`)
  }, "Resett nedtelling"));
};
