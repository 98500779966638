import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import styled from "../../_snowpack/pkg/@emotion/styled.js";
import {sc, useNats, useNatsReq} from "../nats.js";
import {elevation, buttonReset} from "../styles.js";
export const KampKveldControl = () => {
  const nc = useNats();
  let res = useNatsReq("kampkveld.scores");
  if (res.error) {
    return /* @__PURE__ */ jsx("div", null, res.error.toString());
  }
  if (res.isLoading || !res.data) {
    return /* @__PURE__ */ jsx("div", null, "Loading...");
  }
  let {red, blue} = res.data;
  function change(color, delta) {
    nc.publish(`kampkveld.${color}.change`, sc.encode(JSON.stringify(delta)));
  }
  return /* @__PURE__ */ jsx("div", {
    css: css`
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            `
  }, /* @__PURE__ */ jsx(Wrapper, null, /* @__PURE__ */ jsx("div", {
    className: "teamName"
  }, "RED TEAM"), /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      change("red", 1);
    }
  }, /* @__PURE__ */ jsx(Plus, null)), /* @__PURE__ */ jsx("div", {
    className: "digits red"
  }, red), /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      change("red", -1);
    }
  }, /* @__PURE__ */ jsx(Minus, null)), /* @__PURE__ */ jsx("div", {
    className: "teamName"
  }, "BLUE TEAM"), /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      change("blue", 1);
    }
  }, /* @__PURE__ */ jsx(Plus, null)), /* @__PURE__ */ jsx("div", {
    className: "digits blue"
  }, blue), /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      change("blue", -1);
    }
  }, /* @__PURE__ */ jsx(Minus, null))), /* @__PURE__ */ jsx("div", {
    css: css`
                    display: flex;
                    justify-content: center;
                    margin-top: 24px;
                    gap: 12px;

                    button {
                        ${buttonReset};
                        font-size: 2em;
                        padding: 12px;
                        ${elevation[4]};
                    }
                `
  }, /* @__PURE__ */ jsx("button", {
    onClick: () => {
      nc.publish(`presenter.view.kampkveld`);
    }
  }, "Vis poengsum"), /* @__PURE__ */ jsx("button", {
    onClick: () => {
      nc.publish(`presenter.view.logo`);
    }
  }, "Skjul poengsum")));
};
const Button = styled.button`
    ${buttonReset};
    display: block;
    width: 128px;
    height: 64px;
    ${elevation[4]}
    font-weight: 900;
    line-height: 1;
    font-size: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;

    & svg {
        display: block;
        height: 80%;
    }

    &:hover {
        ${elevation[8]}
    }

    &:active {
        ${elevation[1]}
    }

    &:focus {
        outline: none;
    }
`;
const Wrapper = styled.div`
    font-size: 1.7vh;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    grid-auto-flow: column;
    grid-column-gap: 6em;
    justify-content: center;
    align-content: center;

    .teamName {
        text-align: center;
        font-size: 6em;
        font-weight: 900;
    }

    .digits {
        font-family: dseg7;
        font-size: 20em;
        text-align: center;
        &.red {
            color: #d50000;
        }

        &.blue {
            color: #0067ff;
        }
    }
`;
const Plus = () => /* @__PURE__ */ jsx("svg", {
  "aria-hidden": "true",
  focusable: "false",
  "data-prefix": "fas",
  "data-icon": "plus",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 448 512",
  style: {display: "block"}
}, /* @__PURE__ */ jsx("path", {
  fill: "currentColor",
  d: "M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
}));
const Minus = () => /* @__PURE__ */ jsx("svg", {
  "aria-hidden": "true",
  focusable: "false",
  "data-prefix": "fas",
  "data-icon": "minus",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 448 512",
  style: {display: "block"}
}, /* @__PURE__ */ jsx("path", {
  fill: "currentColor",
  d: "M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
}));
