import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {natsPublishJson, useNats} from "../nats.js";
import {elevation, buttonReset} from "../styles.js";
export const MoveItControl = () => {
  const nc = useNats();
  function setMoveIt(type) {
    natsPublishJson(nc, "presenter.view.set", {
      t: "Moveit",
      c: type
    });
  }
  return /* @__PURE__ */ jsx("div", {
    css: css`
                justify-content: center;
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                margin: 64px;
                button {
                    ${buttonReset};
                    display: block;
                    min-width: 128px;
                    height: 64px;
                    ${elevation[4]}
                }
            `
  }, /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`presenter.view.moveit`)
  }, "Reset"), /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`presenter.view.moveit_random`)
  }, "Random"), /* @__PURE__ */ jsx("button", {
    onClick: () => setMoveIt("Frem")
  }, "Frem"), /* @__PURE__ */ jsx("button", {
    onClick: () => setMoveIt("Bak")
  }, "Bak"), /* @__PURE__ */ jsx("button", {
    onClick: () => setMoveIt("Stille")
  }, "Stille"), /* @__PURE__ */ jsx("button", {
    onClick: () => setMoveIt("Ned")
  }, "Ned"));
};
