import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {useEffect} from "../../_snowpack/pkg/react.js";
import {useNats} from "../nats.js";
import {elevation, buttonReset} from "../styles.js";
export const CharadesControl = () => {
  const nc = useNats();
  useEffect(() => {
    let handler = (ev) => {
      if (ev.key === "a") {
        nc.publish(`charades.next.a`);
      } else if (ev.key === "l") {
        nc.publish(`charades.next.b`);
      }
    };
    window.document.addEventListener("keydown", handler);
    return () => {
      window.document.removeEventListener("keydown", handler);
    };
  }, []);
  return /* @__PURE__ */ jsx("div", {
    css: css`
                justify-content: center;
                margin: 64px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                button {
                    ${buttonReset};
                    display: block;
                    min-width: 128px;
                    height: 64px;
                    ${elevation[4]}
                }
            `
  }, /* @__PURE__ */ jsx("button", {
    css: css`
                    margin-bottom: 24px;
                `,
    onClick: () => nc.publish(`charades.start`)
  }, "Start mimeleken"), /* @__PURE__ */ jsx("div", {
    css: css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;

                    button {
                        height: 256px;
                        font-size: 72px;
                        padding: 24px;
                        width: 280px;
                    }
                `
  }, /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`charades.next.a`)
  }, "Venstre (a)"), /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`charades.next.b`)
  }, "Høyre (l)")), /* @__PURE__ */ jsx("div", {
    css: css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    margin-top: 24px;
                `
  }, /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`charades.prev.a`)
  }, "Venstre (tilbake)"), /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`charades.reset`)
  }, "Resett alt"), /* @__PURE__ */ jsx("button", {
    onClick: () => nc.publish(`charades.prev.b`)
  }, "Høyre (tilbake)")));
};
