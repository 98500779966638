import {css, jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {useNats} from "../../nats.js";
import {button} from "../../styles.js";
const Button = styled.button`
    ${button};
    width: 100%;
`;
export const KampKveldAdmin = () => {
  const nc = useNats();
  return /* @__PURE__ */ jsx("div", {
    css: css`
                button {
                    display: block;
                    margin-bottom: 10px;
                }
            `
  }, /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      nc.publish(`presenter.view.kampkveld`);
    }
  }, "Vis poengsum"), /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      nc.publish(`presenter.view.moveit`);
    }
  }, 'Vis "Frem, Bak, Stille, Ned"'), /* @__PURE__ */ jsx(Button, {
    onClick: () => {
      nc.publish(`charades.view`);
    }
  }, 'Vis "Mimeleken"'));
};
