import React from "../../_snowpack/pkg/react.js";
export const GuideContent = () => {
  return /* @__PURE__ */ React.createElement("div", {
    id: "guideContent"
  }, /* @__PURE__ */ React.createElement("title", null, "Tanumpoint Guide"), /* @__PURE__ */ React.createElement("base", {
    href: "./"
  }), /* @__PURE__ */ React.createElement("meta", {
    id: "root-path",
    "root-path": "./"
  }), /* @__PURE__ */ React.createElement("meta", {
    name: "viewport",
    content: "width=device-width, initial-scale=1.0, user-scalable=yes, minimum-scale=1.0, maximum-scale=5.0"
  }), /* @__PURE__ */ React.createElement("h2", {
    id: "available-interfaces"
  }, "Available interfaces"), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("a", {
    href: "/presenter"
  }, "/presenter"), /* @__PURE__ */ React.createElement("a", {
    href: "/lights"
  }, "/lights"), /* @__PURE__ */ React.createElement("a", {
    href: "/mime"
  }, "/mime"), /* @__PURE__ */ React.createElement("a", {
    href: "/kampkveld"
  }, "/kampkveld"), /* @__PURE__ */ React.createElement("a", {
    href: "/moveit"
  }, "/moveit")), /* @__PURE__ */ React.createElement("h2", {
    id: "formatting-songs"
  }, "Formatting songs"), /* @__PURE__ */ React.createElement("p", null, "Create a blank text file, it is recommended to name it the name of the song but it is up to you. The first line of the file is name of the song that shows up in Tanumpoint"), /* @__PURE__ */ React.createElement("h3", {
    id: "pagebreaks"
  }, "Pagebreaks"), /* @__PURE__ */ React.createElement("p", null, "To separate slides use three dashes  ", /* @__PURE__ */ React.createElement("code", null, "---")), /* @__PURE__ */ React.createElement("h3", {
    id: "structure"
  }, "Structure"), /* @__PURE__ */ React.createElement("p", null, "To define a songs structure you can use ", /* @__PURE__ */ React.createElement("code", null, "==="), " followed by what section it is. So for example you can define a verse by writing ", /* @__PURE__ */ React.createElement("code", null, "=== Verse 1"), " or a chorus by writing ", /* @__PURE__ */ React.createElement("code", null, "=== Ref")), /* @__PURE__ */ React.createElement("p", null, "You can reference previous parts of a song using ", /* @__PURE__ */ React.createElement("code", null, "===>"), " and then the name of the part. For example: ", /* @__PURE__ */ React.createElement("code", null, "===> Ref"), ". This will insert the part you referenced there. This is particularly useful for songs with repeating parts like verses."), /* @__PURE__ */ React.createElement("h3", {
    id: "special-pages"
  }, "Special pages"), /* @__PURE__ */ React.createElement("p", null, "To customize slides more you can use ", /* @__PURE__ */ React.createElement("code", null, "~md"), ". This enables markdown editing for that page"), /* @__PURE__ */ React.createElement("p", null, "To get a blank page you can use ", /* @__PURE__ */ React.createElement("code", null, "~blank")), /* @__PURE__ */ React.createElement("p", null, "To get a logo page you can use ", /* @__PURE__ */ React.createElement("code", null, "~logo")), /* @__PURE__ */ React.createElement("h2", {
    id: "using-media"
  }, "Using media"), /* @__PURE__ */ React.createElement("h3", {
    id: "video"
  }, "Video"), /* @__PURE__ */ React.createElement("p", null, "To get a video in a slide use ", /* @__PURE__ */ React.createElement("code", null, "video[/media/filname]"), ". When the video is done it will move to the next slide"), /* @__PURE__ */ React.createElement("p", null, "To have it hold at the end of the slide you can use ", /* @__PURE__ */ React.createElement("code", null, "video[/media/filename]stop")), /* @__PURE__ */ React.createElement("h3", {
    id: "images"
  }, "Images"), /* @__PURE__ */ React.createElement("p", null, "To get an image in a slide use ", /* @__PURE__ */ React.createElement("code", null, "img[/media/filname]")), /* @__PURE__ */ React.createElement("h3", {
    id: "supported-filetypes"
  }, "Supported filetypes"), /* @__PURE__ */ React.createElement("p", null, "jpg, png, mov, mp4"));
};
export const HelpSymbol = () => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  height: "24px",
  viewBox: "0 -960 960 960",
  width: "24px",
  fill: "#e8eaed"
}, /* @__PURE__ */ React.createElement("path", {
  d: "M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
}));
