import styled from "../../_snowpack/pkg/@emotion/styled.js";
import React from "../../_snowpack/pkg/react.js";
export const ViewHtml = ({html}) => {
  return /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement("div", {
    className: "inner",
    dangerouslySetInnerHTML: {__html: html}
  }), " ");
};
const Wrapper = styled.div`
    text-align: center;
    font-size: 6em;
    color: #fff;
    height: 100%;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;

    & :first-child {
        margin-top: 0px;
    }

    & table {
        margin: 0 auto;
        border-collapse: collapse;
        line-height: 1.2;
        font-size: 0.7em;
    }

    & th {
        text-align: left;
        padding: 0 0.3em;
        font-size: 0.7em;
        text-transform: uppercase;
    }

    & td {
        text-align: left;
        padding: 0.15em 0.3em;
    }

    & h1 {
        font-size: 2em;
        margin: 0.25em 0;
        font-weight: 900;
    }

    & h2 {
        font-size: 1em;
        margin: 0.25em 0;
    }

    & p {
        margin: 0.5em 0;
    }
`;
