import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {animated, useSpring} from "../../_snowpack/pkg/react-spring.js";
export const MoveIt = ({type}) => {
  return /* @__PURE__ */ jsx("div", {
    css: css`
                height: 100vh;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 600px 1fr;
                align-items: stretch;
                justify-items: center;
            `
  }, /* @__PURE__ */ jsx(Column, {
    show: type == "Frem",
    color: "#565374"
  }), /* @__PURE__ */ jsx(Column, {
    show: type == "Bak",
    color: "#B72732"
  }), /* @__PURE__ */ jsx(Column, {
    show: type == "Stille",
    color: "#B4A144"
  }), /* @__PURE__ */ jsx(Column, {
    show: type == "Ned",
    color: "#24642b"
  }), /* @__PURE__ */ jsx("div", {
    css: css`
                    z-index: 1;
                    background: #9f7250;
                    grid-column: 1 / span 4;
                    grid-row: 2;
                    justify-self: stretch;

                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    justify-items: center;
                    align-items: start;
                    padding-top: 24px;
                `
  }, /* @__PURE__ */ jsx(Text, {
    color: "#565374"
  }, "Frem"), /* @__PURE__ */ jsx(Text, {
    color: "#B72732"
  }, "Bak"), /* @__PURE__ */ jsx(Text, {
    color: "#B4A144"
  }, "Stille"), /* @__PURE__ */ jsx(Text, {
    color: "#24642b"
  }, "Ned")));
};
const Text = ({color, children}) => {
  return /* @__PURE__ */ jsx("div", {
    css: css`
                color: ${color};
                font-weight: bold;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-size: 128px;
                text-transform: uppercase;
            `
  }, children);
};
const Column = ({color, show}) => {
  const props = useSpring({
    to: {transform: `translateY(${show ? "50px" : `568px`})`},
    config: {
      mass: 1,
      tension: 300,
      friction: 10,
      clamp: true
    }
  });
  return /* @__PURE__ */ jsx(animated.div, {
    css: css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `,
    style: props
  }, /* @__PURE__ */ jsx("div", {
    css: css`
                    background: #9f7250;
                    width: 330px;
                    height: 38px;
                `
  }), /* @__PURE__ */ jsx("div", {
    css: css`
                    background: ${color};
                    width: 220px;
                    flex-grow: 1;
                `
  }));
};
