import {useNatsReq} from "../nats.js";
export function useSong(id) {
  return useNatsReq(`songdb.songs.${id}`);
}
export function useSongList() {
  let songs = useNatsReq(`songdb.song_list`);
  if (!songs.data) {
    return songs;
  }
  let root = {children: [], folders: {}};
  songs.data.forEach((song) => {
    let parts = song.id.split(/[\/\\]/);
    let ptr = root;
    parts.slice(0, parts.length - 1).forEach((s) => {
      if (ptr.folders[s] === void 0) {
        ptr.folders[s] = {
          children: [],
          folders: {}
        };
      }
      ptr = ptr.folders[s];
    });
    ptr.children.push(song);
  });
  return {...songs, data: root};
}
