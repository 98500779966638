"use client";
import React, {useEffect, useRef} from "../../_snowpack/pkg/react.js";
import io from "../../_snowpack/pkg/socket.io-client.js";
const secret = "iak1ahChah0chae1PhieWaithahchi";
const socket = io("wss://ws.tanumpoint.no");
const availableSrc = ["https://tanumpoint.no/presenter", "https://timer.tanumpoint.no/timer?main=title&secondary-src=custom-hvem"];
export const TimerController = () => {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = React.useState("https://tanumpoint.no/presenter");
  useEffect(() => {
    socket.on("iframeUpdate", (message, data) => {
      console.log("Received message:", message);
      console.log("Received data:", data);
      setIframeSrc(message.iframeSrc);
    });
  }, []);
  function updateIframeSrc(src) {
    const message = {
      iframeSrc: src,
      action: "updateIframeSrc",
      secret
    };
    console.log("Sending message:", message);
    socket.emit("iframeUpdate", message);
  }
  return /* @__PURE__ */ React.createElement("div", {
    style: {width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}
  }, /* @__PURE__ */ React.createElement("ol", null, availableSrc.map((src, index) => /* @__PURE__ */ React.createElement("button", {
    key: index,
    onClick: () => updateIframeSrc(src),
    style: {color: "black", backgroundColor: "white", borderRadius: "5px", padding: "10px", margin: "10px"}
  }, src))), /* @__PURE__ */ React.createElement("iframe", {
    ref: iframeRef,
    src: iframeSrc,
    width: "1080px",
    height: "720px",
    title: "Iframe",
    allow: "fullscreen; autoplay;"
  }), /* @__PURE__ */ React.createElement("button", {
    onClick: () => window.open("https://timer.tanumpoint.no/editor", "_blank"),
    style: {color: "black", backgroundColor: "white", borderRadius: "5px", padding: "10px", margin: "10px"}
  }, "Timer editor"));
};
