import React from "../../../_snowpack/pkg/react.js";
import {buttonReset} from "../../styles.js";
import {CaretRight} from "../../components/icons.js";
import {useLocalStorage} from "../../util.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {useSongList} from "../../data/song.js";
import {useAtom} from "../../../_snowpack/pkg/jotai.js";
import {previewSongAtom} from "../../data/preview.js";
export const SongList = () => {
  const songList = useSongList();
  const [openedFolders, setOpenedFolders] = useLocalStorage("opened-folders", {});
  const isOpen = (folder) => openedFolders[folder];
  const setIsOpen = (folder, isOpen2) => {
    if (isOpen2) {
      setOpenedFolders((folders) => ({
        ...folders,
        [folder]: true
      }));
    } else {
      setOpenedFolders((folders) => ({
        ...folders,
        [folder]: false
      }));
    }
  };
  if (songList.error) {
    return /* @__PURE__ */ React.createElement("div", null, songList.error.toString());
  }
  if (songList.isLoading || !songList.data) {
    return /* @__PURE__ */ React.createElement("div", null, "Loading...");
  }
  return /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement(SongFolderC, {
    isOpen,
    setIsOpen,
    folder: songList.data
  }));
};
export const SongFolderC = ({folder, isOpen, setIsOpen}) => {
  const [_, setPreviewSong] = useAtom(previewSongAtom);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, Object.keys(folder.folders).map((folderName) => {
    let thisIsOpen = isOpen(folderName);
    return /* @__PURE__ */ React.createElement(React.Fragment, {
      key: folderName
    }, /* @__PURE__ */ React.createElement(FolderName, {
      open: thisIsOpen,
      onClick: () => setIsOpen(folderName, !thisIsOpen)
    }, /* @__PURE__ */ React.createElement(CaretRight, null), folderName), thisIsOpen && /* @__PURE__ */ React.createElement(FolderContent, null, /* @__PURE__ */ React.createElement(SongFolderC, {
      isOpen,
      setIsOpen,
      folder: folder.folders[folderName]
    })));
  }), folder.children.map((song) => /* @__PURE__ */ React.createElement(Song, {
    key: song.id,
    onClick: () => {
      setPreviewSong(song.id);
    }
  }, song.title)));
};
const Wrapper = styled.div``;
const Song = styled.button`
    ${buttonReset};
    display: block;
    margin: 5px 0;
    cursor: pointer;
`;
const FolderName = styled.button`
    ${buttonReset};
    display: flex;
    align-items: center;
    svg {
        height: 24px;
        width: 16px;
        margin-right: 8px;
    }

    ${(props) => props.open && `
        svg {
            transform: rotate(45deg);
        }
    `};
`;
const FolderContent = styled.div`
    padding-left: 16px;
    border-left: 1px dashed #333;
`;
