import styled from "../../_snowpack/pkg/@emotion/styled.js";
import React from "../../_snowpack/pkg/react.js";
export const Logo = () => /* @__PURE__ */ React.createElement(LogoStyle, null, /* @__PURE__ */ React.createElement("div", {
  className: "small"
}, "TANUM MENIGHET"), /* @__PURE__ */ React.createElement("div", {
  className: "large"
}, "HEMS"), /* @__PURE__ */ React.createElement("div", {
  className: "large"
}, "EDAL"), /* @__PURE__ */ React.createElement("div", {
  className: "large"
}, "2024"));
const LogoStyle = styled.div`
    font-family: 'edo';
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1;
    

    .large {
        font-size: 15em;
        margin-bottom: 0.0em;
    }

    .small {
        font-size: 3em;
        margin-bottom: 0.0em;
    }
`;
