import React from "../../_snowpack/pkg/react.js";
export const ArrowLeft = () => /* @__PURE__ */ React.createElement("svg", {
  "aria-hidden": "true",
  focusable: "false",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 448 512"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
}));
export const ArrowRight = () => /* @__PURE__ */ React.createElement("svg", {
  "aria-hidden": "true",
  focusable: "false",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 448 512"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
}));
export const CaretRight = () => /* @__PURE__ */ React.createElement("svg", {
  "aria-hidden": "true",
  focusable: "false",
  role: "img",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 192 512"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
}));
