import {css} from "../_snowpack/pkg/@emotion/react.js";
import {normalize, lighten} from "../_snowpack/pkg/polished.js";
const bg = "#121212";
export const colors = {
  bg,
  textHi: `rgba(255, 255, 255, 0.87)`,
  textMed: `rgba(255, 255, 255, 0.60)`,
  contrast: "#7F24FF"
};
export const elevation = {
  1: `
        background: ${lighten(0.05, bg)};
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
    `,
  2: `
        background: ${lighten(0.07, bg)};
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
    `,
  3: `
        background: ${lighten(0.08, bg)};
        box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);
    `,
  4: `
        background: ${lighten(0.09, bg)};
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    `,
  6: `
        background: ${lighten(0.11, bg)};
        box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20);
    `,
  8: `
        background: ${lighten(0.12, bg)};
        box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
    `,
  9: `
        background: ${lighten(0.13, bg)};
        box-shadow: 0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20);
    `,
  12: `
        background: ${lighten(0.14, bg)};
        box-shadow: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);
    `,
  16: `
        background: ${lighten(0.15, bg)};
        box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);
    `,
  24: `
        background: ${lighten(0.16, bg)};
        box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);
    `
};
export const p1 = css`
    letter-spacing: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
`;
export const globalStyles = css`
    /* https://google-webfonts-helper.herokuapp.com/fonts/source-sans-pro?subsets=latin */
    /* source-sans-pro-300 - latin */
    @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 300;
        src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
            url('/fonts/source-sans-pro-v12-latin-300.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/fonts/source-sans-pro-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* source-sans-pro-regular - latin */
    @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
            url('/fonts/source-sans-pro-v12-latin-regular.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/fonts/source-sans-pro-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* source-sans-pro-italic - latin */
    @font-face {
        font-family: 'Source Sans Pro';
        font-style: italic;
        font-weight: 400;
        src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
            url('/fonts/source-sans-pro-v12-latin-italic.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/fonts/source-sans-pro-v12-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* source-sans-pro-600 - latin */
    @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
            url('/fonts/source-sans-pro-v12-latin-600.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/fonts/source-sans-pro-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* source-sans-pro-700 - latin */
    @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
            url('/fonts/source-sans-pro-v12-latin-700.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/fonts/source-sans-pro-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* source-sans-pro-900 - latin */
    @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 900;
        src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
            url('/fonts/source-sans-pro-v12-latin-900.woff2') format('woff2'),
            /* Chrome 26+, Opera 23+, Firefox 39+ */
                url('/fonts/source-sans-pro-v12-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    @font-face {
        font-family: 'edo';
        src: url('/fonts/edo.woff2') format('woff2');
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'dseg7';
        src: url('/fonts/DSEG7Modern-Bold.woff2') format('woff2');
        font-style: normal;
        font-weight: 400;
        font-display: block;
    }

    ${normalize()};
    html {
        box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    body {
        font-family: 'Source Sans Pro', sans-serif;
        ${p1};
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${colors.textMed};
        background: ${colors.bg};
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
`;
export const buttonReset = css`
    font: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    &:disabled {
        cursor: default;
    }
`;
export const linkReset = css`
    margin: 0;
    text-decoration: none;
    color: inherit;
`;
export const button = css`
    ${buttonReset};
    background-color: #fff;
    color: #4a4a4a;
    padding: 3px 5px;
    border-radius: 2px;
    &:active {
        background-color: #fff;
    }
    &:hover {
        background-color: #ccc;
    }
`;
