import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {add} from "../../_snowpack/pkg/date-fns.js";
import {useEffect, useState} from "../../_snowpack/pkg/react.js";
export const Counter = ({startTime}) => {
  let timerStart = startTime ? new Date(startTime) : null;
  let [now, setNow] = useState(() => new Date());
  useEffect(() => {
    let interval = setInterval(() => {
      setNow(new Date());
    }, 50);
    return () => {
      clearInterval(interval);
    };
  }, []);
  let endTime = timerStart && add(timerStart, {minutes: 2});
  let duration = endTime && endTime.getTime() - now.getTime();
  let seconds = duration && Math.floor(duration / 1e3);
  let milliseconds = duration && Math.floor(duration / 10) % 100;
  let minutes = seconds && Math.floor(seconds / 60);
  seconds = seconds && seconds % 60;
  let hasEnded = endTime && endTime < now;
  return /* @__PURE__ */ jsx("div", {
    css: css`
                display: flex;
                height: 100%;

                justify-content: center;
                align-items: center;
                font-size: 15em;
                text-align: center;
                font-family: dseg7;
            `
  }, hasEnded ? "00:00:00" : duration ? `${formatInt(minutes || 0)}:${formatInt(seconds || 0)}:${formatInt(milliseconds || 0)}` : "02:00:00");
};
const WORDS_A = [
  "Red Team",
  "Gummistøvler",
  "Bølge",
  "Krangle",
  "Politibåt",
  "Basketball",
  "Due",
  "Viskelær",
  "Motorsag",
  "Elefant",
  "Gitar",
  "Salmebok",
  "Minibuss",
  "Fredrik",
  "Sky",
  "Telt",
  "Ambulanse fly",
  "Fallskjerm-hopping",
  "Svane",
  "Bordtennis",
  "Gressklipper",
  "Kritt",
  "Trompet",
  "Ku",
  "Tog",
  "Bibel"
];
const WORDS_B = [
  "Blue Team",
  "Sky",
  "Telt",
  "Ambulanse fly",
  "Fallskjerm-hopping",
  "Svane",
  "Bordtennis",
  "Gressklipper",
  "Kritt",
  "Trompet",
  "Ku",
  "Tog",
  "Bibel",
  "Fredrik",
  "Gummistøvler",
  "Bølge",
  "Krangle",
  "Politibåt",
  "Basketball",
  "Due",
  "Viskelær",
  "Motorsag",
  "Elefant",
  "Gitar",
  "Salmebok",
  "Minibuss"
];
function formatInt(number) {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number.toString();
  }
}
