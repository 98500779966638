import {css, jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {useRef} from "../../../_snowpack/pkg/react.js";
import {useEffect} from "../../../_snowpack/pkg/react.js";
import {FILE_SERVE_URL} from "../../api.js";
import {Logo} from "../../components/Logo.js";
import {ViewHtml} from "../../components/ViewHtml.js";
import {natsPublishJson, useNats, useNatsReq} from "../../nats.js";
import {buttonReset, colors, elevation} from "../../styles.js";
import {usePrevious} from "../../util.js";
export const SongPreview = ({song}) => {
  const activeVerseReq = useNatsReq("song_player.song_verse");
  const nats = useNats();
  const selectVerse = (groupIndex, verseIndex) => {
    natsPublishJson(nats, "song_player.song_verse.set", {
      song: song.id,
      group: groupIndex,
      verse: verseIndex
    });
  };
  const activeVerse = activeVerseReq.data || null;
  return /* @__PURE__ */ jsx("div", null, song.groups.map((group, groupIndex) => /* @__PURE__ */ jsx("div", {
    key: groupIndex
  }, /* @__PURE__ */ jsx("div", {
    css: css`
                            margin-bottom: 4px;
                            font-weight: bold;
                            position: sticky;
                            top: 0;
                        `
  }, group.name), group.verses.map((verse, verseIndex) => {
    let active = !!(activeVerse && activeVerse.song === song.id && activeVerse.group === groupIndex && activeVerse.verse === verseIndex);
    return /* @__PURE__ */ jsx(VerseStyle, {
      key: verseIndex,
      active,
      onClick: () => selectVerse(groupIndex, verseIndex)
    }, /* @__PURE__ */ jsx(VersePreview, {
      active,
      verse
    }));
  }))));
};
const VersePreview = ({verse, active}) => {
  let ref = useRef(null);
  let prevActive = usePrevious(active);
  useEffect(() => {
    if (!prevActive && active && ref.current) {
      ref.current.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }, [active]);
  switch (verse.t) {
    case "Lines":
      return /* @__PURE__ */ jsx("div", {
        ref
      }, verse.c.map((line, index) => /* @__PURE__ */ jsx("div", {
        key: index
      }, line)));
    case "Blank":
      return /* @__PURE__ */ jsx("div", {
        ref,
        style: {height: 100}
      });
    case "Logo":
      return /* @__PURE__ */ jsx("div", {
        ref,
        style: {fontSize: 2.6}
      }, /* @__PURE__ */ jsx(Logo, null));
    case "Image":
      return /* @__PURE__ */ jsx(Image, {
        ref,
        src: FILE_SERVE_URL + verse.c
      });
    case "Video":
      return /* @__PURE__ */ jsx("div", {
        ref
      }, "Video: ", verse.c.url);
    case "Html":
      return /* @__PURE__ */ jsx("div", {
        ref,
        style: {fontSize: 2.6}
      }, /* @__PURE__ */ jsx(ViewHtml, {
        html: verse.c
      }));
    default:
      return assertNever(verse);
  }
};
function assertNever(x) {
  return /* @__PURE__ */ jsx("div", null, "Unexpected: ", JSON.stringify(x));
}
const Image = styled.img`
    display: block;
    width: 100%;
    height: 186px;
    object-fit: contain;
`;
const VerseStyle = styled.button`
    ${buttonReset};
    display: block;
    width: 100%;
    margin: 0 0 20px;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    max-height: 210px;
    color: ${colors.textMed};

    transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out, color 0.2s ease-out;

    ${elevation[1]};

    ${(props) => props.active && `
        ${elevation[16]};
        color: ${colors.textHi};

        `};

    &:focus {
        outline: none;
    }

    &:hover {
        ${(props) => !props.active && elevation[2]};
    }
`;
