import React, {useEffect, useState, useRef} from "../../_snowpack/pkg/react.js";
import io from "../../_snowpack/pkg/socket.io-client.js";
import "./editor.css";
import {GuideContent, HelpSymbol} from "../components/Editor.js";
const secret = "iak1ahChah0chae1PhieWaithahchi";
const socket = io("wss://ws.tanumpoint.no");
function TextEditor({currentFile, setCurrentFile, setSongDB, songDB}) {
  const [inputValue, setInputValue] = useState(currentFile.content || "");
  function pushFile(filePath, content) {
    const sendMessage = (fileContent) => {
      setCurrentFile({name: content.split("\n")[0].trim(), path: currentFile.path, content: fileContent.content});
      let currentSongDB = songDB;
      currentSongDB[filePath].content = fileContent.content;
      setSongDB(currentSongDB);
      socket.emit("updateFile", fileContent);
    };
    sendMessage({path: filePath, content, secret});
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => pushFile(currentFile.path, inputValue), 300);
    return () => clearTimeout(timeoutId);
  }, [inputValue]);
  useEffect(() => {
    setInputValue(currentFile.content || "");
  }, [currentFile]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "editor"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "songTitle"
  }, currentFile.name), /* @__PURE__ */ React.createElement("textarea", {
    className: "input-field",
    value: inputValue,
    onChange: (e) => {
      setInputValue(e.target.value);
    }
  }));
}
;
function Folder({folderName, fileList}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let openedFolders = {};
    try {
      openedFolders = JSON.parse(localStorage.getItem("openedFolders")) || {};
    } catch {
      openedFolders = {};
    }
    setOpen(openedFolders[folderName]);
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    key: folderName
  }, /* @__PURE__ */ React.createElement("p", {
    key: folderName,
    onClick: () => setOpen(!open),
    className: "folder"
  }, open ? /* @__PURE__ */ React.createElement("svg", {
    "aria-hidden": "true",
    focusable: "false",
    role: "img",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 192 512",
    style: {transform: "rotate(90deg)", height: "1em"}
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: "M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
  })) : /* @__PURE__ */ React.createElement("svg", {
    "aria-hidden": "true",
    focusable: "false",
    role: "img",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 192 512",
    style: {height: "1em"}
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: "M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
  })), folderName), open && /* @__PURE__ */ React.createElement("div", {
    key: folderName + "div",
    className: "folder-content"
  }, fileList));
}
function File({file, setCurrentFile, path}) {
  return /* @__PURE__ */ React.createElement("p", {
    className: "file",
    key: path,
    onClick: () => {
      console.log(file);
      setCurrentFile({name: file.name, path, content: file.content});
    }
  }, "  ", file.name);
}
function CreateFile({setCurrentFile, songDB, path = ""}) {
  const createFileRef = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = createFileRef.current.checkValidity();
    if (isFormValid) {
      const filePath = createFileRef.current["newFile"].value.toLowerCase().replace(/[^\w\s.-]/g, "_");
      const fileTitle = createFileRef.current["newFile"].value;
      socket.emit("createFile", {fileName: filePath + ".txt", content: fileTitle + "\n---", secret});
      createFileRef.current.reset();
    } else {
      createFileRef.current.reportValidity();
    }
  };
  return /* @__PURE__ */ React.createElement("form", {
    ref: createFileRef,
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement("input", {
    type: "text",
    name: "newFile",
    placeholder: "New File",
    id: "newFileInput",
    required: true
  }), /* @__PURE__ */ React.createElement("button", {
    type: "submit"
  }, "Create"));
}
function FileList({setCurrentFile, currentFile, songDB}) {
  function generateElementList(songDB2, path = []) {
    const files = Object.keys(songDB2);
    const elements = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (songDB2[file].isFolder) {
        elements.push(/* @__PURE__ */ React.createElement(Folder, {
          folderName: file,
          fileList: generateElementList(songDB2[file].path)
        }));
      } else if (!songDB2[file].isFolder) {
        elements.push(/* @__PURE__ */ React.createElement(File, {
          file: songDB2[file],
          path: file,
          setCurrentFile
        }));
      }
    }
    return elements;
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "fileList",
    key: "fileList"
  }, generateElementList(songDB), /* @__PURE__ */ React.createElement(CreateFile, {
    setCurrentFile,
    path: currentFile.path,
    songDB
  }));
}
function Guide({setGuideOpen, guideOpen}) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    id: "helpSymbol",
    onClick: () => setGuideOpen(!guideOpen)
  }, /* @__PURE__ */ React.createElement(HelpSymbol, null)), guideOpen ? /* @__PURE__ */ React.createElement("div", {
    className: "guide"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "closeGuide",
    onClick: () => setGuideOpen(false)
  }, "X"), /* @__PURE__ */ React.createElement(GuideContent, null)) : null);
}
export const Editor = () => {
  const [songDB, setSongDB] = useState("");
  const [currentFile, setCurrentFile] = useState({name: "", path: "", content: ""});
  const [guideOpen, setGuideOpen] = useState(false);
  function sortByFolder(fileList) {
    let keysList = Object.keys(fileList).map((key) => ({key, ...fileList[key]}));
    keysList.sort((a, b) => a.isFolder && !b.isFolder ? -1 : 1);
    let sorted = {};
    for (let i = 0; i < keysList.length; i++) {
      let element = {};
      element[keysList[i].key] = keysList[i];
      sorted = {...sorted, ...element};
    }
    return sorted;
  }
  const sendMessage = () => {
    socket.emit("message", {secret});
  };
  useEffect(() => sendMessage(), []);
  useEffect(() => {
    setCurrentFile(JSON.parse(localStorage.getItem("openedFile")));
    socket.on("message", (msg) => {
      setSongDB(sortByFolder(msg));
      localStorage.setItem("songDB", JSON.stringify(sortByFolder(msg)));
    });
  }, []);
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      localStorage.setItem("openedFile", JSON.stringify(currentFile));
    }, 1e3);
  }, [currentFile]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "wrapper"
  }, /* @__PURE__ */ React.createElement(Guide, {
    setGuideOpen,
    guideOpen
  }), /* @__PURE__ */ React.createElement(TextEditor, {
    currentFile,
    setCurrentFile,
    setSongDB,
    songDB
  }), /* @__PURE__ */ React.createElement(FileList, {
    setCurrentFile,
    currentFile,
    songDB
  }));
};
