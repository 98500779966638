import {Global, jsx} from "../_snowpack/pkg/@emotion/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import {QueryClient, QueryClientProvider} from "../_snowpack/pkg/react-query.js";
import {ReactQueryDevtools} from "../_snowpack/pkg/react-query/devtools.js";
import {BrowserRouter as Router, Route, Routes} from "../_snowpack/pkg/react-router-dom.js";
import {NatsProvider} from "./nats.js";
import {Control} from "./pages/control/Control.js";
import {KampKveldControl} from "./pages/KampKveldControl.js";
import {Light} from "./pages/Light.js";
import {LightsFixtures} from "./pages/LightsFixtures.js";
import {Lights} from "./pages/Lights.js";
import {CharadesControl} from "./pages/CharadesControl.js";
import {Presenter} from "./pages/Presenter.js";
import {globalStyles} from "./styles.js";
import {MoveItControl} from "./pages/MoveItControl.js";
import {CounterControl} from "./pages/CounterControl.js";
import {Editor} from "./pages/Editor.js";
import {TimerController} from "./pages/TimerController.js";
import {Timer} from "./pages/Timer.js";
const queryClient = new QueryClient();
function App() {
  return /* @__PURE__ */ jsx(NatsProvider, null, /* @__PURE__ */ jsx(QueryClientProvider, {
    client: queryClient
  }, /* @__PURE__ */ jsx(Router, null, /* @__PURE__ */ jsx(Global, {
    styles: globalStyles
  }), /* @__PURE__ */ jsx(Preloader, null), /* @__PURE__ */ jsx(Routes, null, /* @__PURE__ */ jsx(Route, {
    path: "/",
    element: /* @__PURE__ */ jsx(Control, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "presenter",
    element: /* @__PURE__ */ jsx(Presenter, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "kampkveld",
    element: /* @__PURE__ */ jsx(KampKveldControl, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "moveit",
    element: /* @__PURE__ */ jsx(MoveItControl, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "light",
    element: /* @__PURE__ */ jsx(Light, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "lights/fixtures",
    element: /* @__PURE__ */ jsx(LightsFixtures, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "lights",
    element: /* @__PURE__ */ jsx(Lights, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "mime",
    element: /* @__PURE__ */ jsx(CharadesControl, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "counter",
    element: /* @__PURE__ */ jsx(CounterControl, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "edit",
    element: /* @__PURE__ */ jsx(Editor, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "timer",
    element: /* @__PURE__ */ jsx(Timer, null)
  }), /* @__PURE__ */ jsx(Route, {
    path: "timer/controller",
    element: /* @__PURE__ */ jsx(TimerController, null)
  }))), /* @__PURE__ */ jsx(ReactQueryDevtools, {
    initialIsOpen: false
  })));
}
const Preloader = () => /* @__PURE__ */ jsx(HiddenDiv, null, /* @__PURE__ */ jsx("div", {
  style: {fontFamily: "dseg7"}
}, "88"), /* @__PURE__ */ jsx("div", {
  style: {fontFamily: "edo"}
}, "hemsedal"), /* @__PURE__ */ jsx("div", {
  style: {fontWeight: 700}
}, "88"));
const HiddenDiv = styled.div`
    position: absolute;
    left: -99999px;
`;
export default App;
