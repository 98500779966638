import React, {useContext, useEffect, useState} from "../_snowpack/pkg/react.js";
import * as nats from "../_snowpack/pkg/nats.ws.js";
import {useQuery, useQueryClient} from "../_snowpack/pkg/react-query.js";
const NatsContext = React.createContext(null);
export function useNats() {
  let result = useContext(NatsContext);
  if (!result) {
    throw new Error("useNats can only be used inside a NatsProvider");
  }
  return result;
}
export function natsPublishJson(nc, subject, value) {
  nc.publish(subject, sc.encode(JSON.stringify(value)));
}
export const sc = nats.StringCodec();
export const NatsProvider = ({children}) => {
  const [connection, setConnection] = useState(null);
  useEffect(() => {
    let nc;
    nats.connect({
      servers: `wss://nats.tanumpoint.no`,
      maxReconnectAttempts: -1,
      waitOnFirstConnect: true
    }).then((newConnection) => {
      nc = newConnection;
      setConnection(newConnection);
    });
    return () => {
      if (nc) {
        nc.close();
      }
    };
  }, []);
  if (connection) {
    return /* @__PURE__ */ React.createElement(NatsContext.Provider, {
      value: connection
    }, children);
  } else {
    return /* @__PURE__ */ React.createElement("div", null, "Loading...");
  }
};
export function useNatsReq(subject, options) {
  let nats2 = useNats();
  const queryClient = useQueryClient();
  let result = useQuery(["nats_req", subject], () => getNatsReq(nats2, subject), options);
  useEffect(() => {
    if (subject) {
      const sub = nats2.subscribe(subject);
      (async () => {
        for await (const message of sub) {
          await queryClient.cancelQueries(["nats_req", subject]);
          const newValue = JSON.parse(sc.decode(message.data));
          queryClient.setQueryData(["nats_req", subject], newValue);
        }
      })().catch((err) => console.error(err));
      return () => {
        sub.unsubscribe();
      };
    }
  }, [subject]);
  return result;
}
async function getNatsReq(nc, subject) {
  if (!subject) {
    return null;
  }
  let response = await nc.request(`${subject}.get`, nats.Empty, {timeout: 1e3});
  return JSON.parse(sc.decode(response.data));
}
