import {css, jsx} from "../../_snowpack/pkg/@emotion/react.js";
import {Slider, SliderHandleAlignment, SliderOrientation} from "../../_snowpack/pkg/@reach/slider.js";
import "../../_snowpack/pkg/@reach/slider/styles.css";
import {useState} from "../../_snowpack/pkg/react.js";
import {colorToCssColor, PRIMARY_COLORS} from "../lights/colors.js";
import {sc, useNats, useNatsReq} from "../nats.js";
import {buttonReset, colors, elevation} from "../styles.js";
export const LightsFixtures = () => {
  let fixtures = useNatsReq("lights.fixtures", {
    refetchOnWindowFocus: false
  });
  if (fixtures.error) {
    return /* @__PURE__ */ jsx("div", null, fixtures.error.toString());
  }
  if (fixtures.isLoading || !fixtures.data) {
    return /* @__PURE__ */ jsx("div", null, "Loading..");
  }
  return /* @__PURE__ */ jsx("div", {
    css: css`
                padding: 24px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            `
  }, fixtures.data.map((fixture, index) => /* @__PURE__ */ jsx(FixtureControl, {
    fixture,
    index,
    key: fixture.addr
  })));
};
const FixtureControl = ({fixture, index}) => {
  const nc = useNats();
  const [showColor, setShowColor] = useState(false);
  let value = useNatsReq(`lights.fixture.${index}.dimmer`, {
    refetchOnWindowFocus: false
  });
  let color = useNatsReq(`lights.fixture.${index}.color`, {
    refetchOnWindowFocus: false
  });
  if (value.error) {
    return /* @__PURE__ */ jsx("div", null, value.error.toString());
  }
  if (color.error) {
    return /* @__PURE__ */ jsx("div", null, color.error.toString());
  }
  if (value.isLoading || value.data === void 0 || value.data === null || !color.data || color.isLoading) {
    return /* @__PURE__ */ jsx("div", null, "Loading..");
  }
  return /* @__PURE__ */ jsx("div", {
    css: css`
                min-width: 96px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid ${colors.textMed};
                border-radius: 3px;
                margin: 8px;
                padding: 8px 16px;
            `
  }, /* @__PURE__ */ jsx(Slider, {
    css: css`
                    [data-reach-slider-handle] {
                        background: ${colorToCssColor(color.data)};
                        width: 24px;
                        height: 48px;
                    }
                `,
    min: 0,
    max: 1,
    step: 1e-3,
    value: value.data,
    onChange: (value2) => {
      nc.publish(`lights.fixture.${index}.dimmer.set`, sc.encode(JSON.stringify(value2)));
    },
    orientation: SliderOrientation.Vertical,
    handleAlignment: SliderHandleAlignment.Contain
  }), /* @__PURE__ */ jsx("button", {
    onClick: () => {
      setShowColor(true);
    },
    css: css`
                    margin-top: 16px;
                    ${buttonReset};
                    width: 24px;
                    &:hover {
                        color: white;
                    }
                `
  }, /* @__PURE__ */ jsx(PaletteIcon, null)), /* @__PURE__ */ jsx("div", {
    css: css`
                    font-variant: all-small-caps;
                    font-size: 24px;
                    color: ${colors.textHi};
                `
  }, fixture.name), showColor && /* @__PURE__ */ jsx(ChangeColor, {
    index,
    color: color.data,
    close: () => setShowColor(false)
  }));
};
const ChangeColor = ({
  color,
  index,
  close
}) => {
  const nc = useNats();
  return /* @__PURE__ */ jsx("div", {
    onMouseDown: (ev) => {
      if (!ev.target.closest(".change-color-inner")) {
        close();
      }
    },
    css: css`
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: grid;
                place-items: center;
                z-index: 10;
                background: rgba(0, 0, 0, 0.4);
            `
  }, /* @__PURE__ */ jsx("div", {
    className: "change-color-inner",
    css: css`
                    min-width: 100px;
                    min-height: 100px;
                    padding: 24px;
                    ${elevation[6]};
                    border-radius: 4px;
                `
  }, /* @__PURE__ */ jsx("div", {
    css: css`
                        display: flex;
                    `
  }, PRIMARY_COLORS.map((part) => /* @__PURE__ */ jsx("div", {
    css: css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                min-width: 100px;

                                [data-reach-slider-handle] {
                                    background: #ccc;
                                    width: 24px;
                                    height: 48px;
                                }
                            `
  }, /* @__PURE__ */ jsx(Slider, {
    min: 0,
    max: part === "hue" ? 360 : 1,
    step: part === "hue" ? 0.5 : 1e-3,
    value: color[part],
    onChange: (value) => {
      let colorClone = {...color};
      colorClone[part] = value;
      nc.publish(`lights.fixture.${index}.color.set`, sc.encode(JSON.stringify(colorClone)));
    },
    orientation: SliderOrientation.Vertical,
    handleAlignment: SliderHandleAlignment.Contain
  }), /* @__PURE__ */ jsx("div", {
    css: css`
                                    font-variant: all-small-caps;
                                    font-size: 24px;
                                    color: ${colors.textHi};
                                `
  }, part))))));
};
function PaletteIcon() {
  return /* @__PURE__ */ jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 512 512"
  }, /* @__PURE__ */ jsx("path", {
    fill: "currentColor",
    d: "M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
  }));
}
